import { AxiosResponse } from 'axios';
import { sendMonitoringEvent } from '@utilities/common/monitoring';
import { gotoRoute } from '@utilities/common/Path';
import { StorageItems } from '@constants/App';
import { removeItemFromLocalStorage } from '@utilities/common/Storage';

const onResponseFulfilled = (response: AxiosResponse) => {
  console.log('%c Response: ', 'background: #0f0; color: #fff', response);

  return response;
};

const onResponseRejected = (error: any) => {
  sendMonitoringEvent(error);

  console.log('%c Error: ', 'background: #f00; color: #fff', error.response);

  if (error.response.status === 401 || error.response.status === 403) {
    removeItemFromLocalStorage(StorageItems.USER_INFO);
    gotoRoute('/login');
  }
  return Promise.reject(error.response);
};

export { onResponseFulfilled, onResponseRejected };
