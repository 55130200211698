export const tollFreeMsgs: {
  [index: string]: string;
} = {
  '+13053926892': 'INTERNATIONAL_TOLL_FREE',
  '180015500039': 'PhilippinesTollFreeInfo',
  '(02)86263750': 'PhilippinesLocalInfo',
  '+6531386066': 'PhilippinesLocalInfo',
  '6531386067': 'SingaporeNonTollFreeInfo',
  '12280332': 'HELPLINE_VIETTEL_NOTE',
};
