import { StorageItems } from '@constants/App';
import moment from 'moment';
import 'moment-timezone';
import { addItemToLocalStorage, getItemFromLocalStorage } from './Storage';

export const ApiDateFormat = 'YYYY/MM/DD';
export const RecentChatMessageFormat = 'hh:mm A';
export const OldChatMessageFormat = 'dddd, MMM Do hh:mm A';
export const OldChatContactFormat = 'DD/MM/YYYY';
export const OnsiteDateFormat = 'MMMM DD, YYYY';
export const getTimeZone = () => {
  const overriddenTimeZone = getItemFromLocalStorage(
    StorageItems.TIME_ZONE,
    'string',
  ) as string;
  if (overriddenTimeZone) {
    return overriddenTimeZone;
  }

  return moment.tz.guess(true);
};

export const getTimeZoneAbbreviation = () => {
  // * moment don't have all the timezone abbr so we hardcode them
  const missingAbbreviations: Record<string, string> = {
    'Asia/Singapore': 'SGT',
    'Asia/Kabul': 'AFT',
    'Asia/Ho_Chi_Minh': 'IDT',
    'Asia/Saigon': 'ICT',
    'Asia/Hanoi': 'ICT',
    'Asia/Rangoon': 'MMT',
    'Asia/Yangon': 'MMT',
    'Asia/Dubai': 'GST',
    'Asia/Bangkok': 'ICT',
    'Asia/Thimphu': 'BTT',
    'Asia/Kuala_Lumpur': 'MYT',
    'Asia/Kuching': 'MYT',
  };

  const timeZone = getTimeZone();

  return missingAbbreviations[timeZone] || moment.tz(timeZone).format('z');
};

export const getTimeFromMilliSeconds = (milliSeconds: number, format: string) =>
  moment.utc(milliSeconds).format(format);

export const setAppTimeZone = (timeZone: string) => {
  addItemToLocalStorage(StorageItems.TIME_ZONE, timeZone);
};

export const getOnsiteEventTimings = (fromDate: string, fromTime: string) => {
  const timestamp = moment
    .utc(
      `${fromDate} ${Math.floor((+fromTime * 15) / 60).toFixed(0)}:${
        (+fromTime * 15) % 60
      }`,
      'YYYY/MM/DD HH:mm',
    )
    .unix();

  return moment
    .utc(timestamp * 1000)
    .tz(getTimeZone())
    .format('hh:mm A z');
};

const formatNumberTo2Digits = (input: number) => `0${input}`.slice(-2);

/**
 * Generates 15 minutes time
 * @param slot
 * @returns
 */
export const slotTo15MinTime = (slot: number) =>
  `${formatNumberTo2Digits(
    +Math.floor((+slot * 15) / 60).toFixed(0),
  )}:${formatNumberTo2Digits((+slot * 15) % 60)}`;

  
/**
 * Handler to get correct start-end dates based on onsite session slots
 * @param {Object} data 
 * @returns {Object}
 */
export const getStartEndDatesForOnsiteSession = (data: {
  sessionStartSlot: string;
  sessionEndSlot: string;
  onsiteStartDate: string;
  onsiteEndDate: string;
  onsiteStartSlot: string;
  onsiteEndSlot: string;
}) => {
  const {
    onsiteStartDate,
    onsiteStartSlot,
    onsiteEndDate,
    onsiteEndSlot,
    sessionStartSlot,
    sessionEndSlot,
  } = data;

  let startDate = onsiteStartDate;
  let endDate = onsiteStartDate;
  if (+sessionStartSlot >= +onsiteStartSlot) {
    startDate = onsiteStartDate;
  }

  if (
    +sessionStartSlot <= +onsiteStartSlot &&
       +sessionStartSlot <= +onsiteEndSlot
  ) {
    startDate = onsiteEndDate;
  }

  if (
    +sessionEndSlot >= +onsiteStartSlot &&
       +sessionEndSlot >= +onsiteEndSlot
  ) {
    endDate = onsiteStartDate;
  }

  if (+sessionEndSlot <= +onsiteEndSlot) {
    endDate = onsiteEndDate;
  }

  return { startDate, endDate };
};