import { IUnmatchRequest } from '@customTypes/request/provider';
import NetworkClient from '@services/Network';
import { beautifyUrl } from '@utilities/common/Path';
import { IOnsiteBookingRequest } from '@hooks/useOnsiteData/useOnsiteData';
import Config from '../config';

const errorMessages = {
  generic: 'Unable to load the user data, please try again!',
  onsiteEventFetchErrorMsg:
    'Unable to load the onsite events data, please try again!',
  onsiteLocationListFetchErrorMsg:
    'Unable to load the onsite locations data, please try again!',
  onsiteSlotsFetchErrorMsg:
    'Unable to load the onsite slots, please try again!',
  onsiteRescheduleErrorMsg:
    'Unable to reschedule onsite session, please try again!',
  upcomingOnsiteErrorMsg:
    'Unable to load the upcoming onsite sessions, please try again!',
  onsiteBookingErrorMsg: 'Unable to book onsite session, please try again!',
};

const client = new NetworkClient();

export const unmatchProvider = async (unmatchRequest: IUnmatchRequest) => {
  const response = await client.doPost(Config.api.user.unmatch, unmatchRequest);
  if (response && response.success) {
    return response.data?.meetings || [];
  }

  throw new Error(response.error?.message || errorMessages.generic);
};

export const getUserProfile = async () => {
  const response = await client.doGet(Config.api.user.getProfile);
  return response?.data;
};

export const updateUserProfile = async (
  updates: Record<string, any>,
  isV2Update: boolean,
) => {
  let response: any = {};
  if (isV2Update) {
    response = await client.doPost(Config.api.user.updateProfileV2, updates);
    if (response && response.success) {
      return true;
    }
  } else {
    response = await client.doPut(Config.api.user.updateProfile, updates);
    if (response && response.success) {
      return true;
    }
  }

  throw new Error(response.error?.message || response || errorMessages.generic);
};

export const getEmployeeDetails = async (userId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.user.userDetails, [userId]),
  );
  if (response && response.success) {
    return response.data || {};
  }

  throw new Error(response.error?.message || errorMessages.generic);
};

export const getUserSelfAuthenticationData = async (token: string) => {
  const response = await client.doGet(
    Config.api.auth.self,
    // @ts-ignore
    { Authorization: token },
  );
  if (response && response.success) {
    return response.data || {};
  }

  throw new Error(response.error?.message || errorMessages.generic);
};

export const getUserDetail = async (token: string) => {
  const response = await client.doGet(
    Config.api.auth.self,
    // @ts-ignore
    { Authorization: token },
  );
  return response.data;
};

export const getTeamList = async () => {
  const response = await client.doGet(Config.api.user.getTeams);
  if (response && response.success) {
    return response.data || {};
  }

  throw new Error(response.error?.message || errorMessages.generic);
};

export const updateUserTeam = async (body: {
  teamId: number;
  country: string;
}) => {
  const response = await client.doPost(Config.api.user.updateTeams, body);
  if (response && response.success) {
    return response.data?.data || [];
  }

  throw new Error(response.error?.message || errorMessages.generic);
};

export const fetchOnsiteEvents = async (locationIds?: number[]) => {
  const body = locationIds
    ? { filters: [{ field: 'location', value: locationIds }] }
    : {};
  const response = await client.doPost(Config.api.onsite.eventList, body);
  if (response.success) {
    return response.data;
  }
  throw new Error(
    response.error?.message || errorMessages.onsiteEventFetchErrorMsg,
  );
};

export const getOnsiteLocations = async () => {
  const response = await client.doGet(Config.api.onsite.locationList);
  if (response && response.success) {
    return response.data;
  }
  throw new Error(
    response.error?.message || errorMessages.onsiteLocationListFetchErrorMsg,
  );
};

export const getDeploymentDetails = async (onsiteId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.onsite.eventDetails, [onsiteId]),
  );
  if (response && response.success) return response.data;
  throw new Error(
    response.error?.message || errorMessages.onsiteLocationListFetchErrorMsg,
  );
};

export const bookOnsiteEvent = async (body: IOnsiteBookingRequest) => {
  const response = await client.doPost(Config.api.onsite.eventBook, body);
  if (response.success) {
    return response.data;
  }
  throw new Error(
    response.error?.message || errorMessages.onsiteBookingErrorMsg,
  );
};

export const fetchUpcomingOnsiteSession = async ({
  page = 1,
  pageSize = 10,
  filters = [],
}: {
  page?: number;
  pageSize?: number;
  filters?: any;
}) => {
  const body = {
    page,
    pageSize,
    filters,
  };
  const response = await client.doPost(
    Config.api.onsite.upcomingSessions,
    body,
  );
  if (response.success) {
    return response.data;
  }
  throw new Error(
    response.error?.message || errorMessages.upcomingOnsiteErrorMsg,
  );
};

export const getOnsiteRescheduleSlots = async (onsiteId: string) => {
  const response = await client.doGet(
    beautifyUrl(Config.api.onsite.rescheduleSlots, [onsiteId]),
  );
  if (response && response.success) return response.data;
  throw new Error(
    response.error?.message || errorMessages.onsiteSlotsFetchErrorMsg,
  );
};

export const rescheduleOnsiteSession = async ({
  note,
  scheduledStartTime,
  meetingId,
}: {
  note?: string;
  scheduledStartTime: string;
  meetingId: string;
}) => {
  const response = await client.doPut(
    beautifyUrl(Config.api.onsite.sessionReschedule, [meetingId]),
    { note, scheduledStartTime },
  );
  if (response && response.success) return response.data;
  throw new Error(
    response.error?.message || errorMessages.onsiteRescheduleErrorMsg,
  );
};

export const cancelOnsiteSessionService = async ({
  type,
  otherReason,
  meetingId,
}: {
  type?: string;
  otherReason?: string;
  meetingId: string;
}) => {
  const response = await client.doPut(
    beautifyUrl(Config.api.onsite.cancelSession, [meetingId]),
    { reason: { type, otherReason } },
  );
  if (response && response.success) return response.data;
  throw new Error(
    response.error?.message || errorMessages.onsiteRescheduleErrorMsg,
  );
};
