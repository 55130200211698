import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import './BookingManagement.scss';
import Typography from '@widgets/Typography';
import SelectBox from '@widgets/SelectBox';
import {
  getMeetingJoinData,
  useAllBookings,
} from '@hooks/useOnsiteData/useOnsiteData';
import { ProviderRoles } from '@constants/provider';
import { MeetingStatus } from '@constants/meetingStatus';
import NoDataPlaceholder from '@components/NoDataPlaceholder';
import RescheduleSession from '@components/SessionSchedule/RescheduleSession';
import { openNewTab } from '@utilities/common/Path';
import { VideoRoomSDKs } from '@constants/App';
import { getTimeZone, OnsiteDateFormat } from '@utilities/common/Date';
import { ModalTypes } from './constants';
import SessionCard from './SessionCard';
import OnsiteBookCancelPopUp from '../OnsiteBookCancelPopUp/OnsiteBookCancelPopUp';
import useProfileDetails from '../Provider/Home/hooks/useProfileDetails';
import CancelConfirmedSession from '../MeetingProfile/SessionCancelPopup/ConfirmedSession';
import { checkWithin24hrs } from '../MeetingProfile/utils/checkWithin24hrs';

export default function BookingManagement() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: profileData } = useProfileDetails({
    refetchOnWindowFocus: false,
  });
  const { phoneNumber } = profileData || {};

  const [sessionType, setSessionType] = React.useState('all');
  const [editOnsiteModal, setEditOnsiteModal] = React.useState({
    isOpen: false,
    id: '',
    name: ModalTypes.noModal,
  });

  const filters = React.useMemo(
    () => [
      {
        field: 'status',
        value: [MeetingStatus.scheduled],
      },
      sessionType === 'all'
        ? {}
        : {
          field: 'role',
          value:
            sessionType === 'virtual'
              ? Object.values(ProviderRoles).filter(
                (role) => role !== ProviderRoles.ONSITE,
              )
              : [ProviderRoles.ONSITE],
        },
    ],
    [sessionType],
  );

  const { meetings, isLoading, refetchAllBookings } = useAllBookings(filters);
  const { getMeetingData } = getMeetingJoinData();
  const sessionTypesOptions = [
    {
      label: 'All Sessions',
      value: 'all',
    },
    {
      label: 'Virtual Counselling',
      value: 'virtual',
    },
    {
      label: 'Onsite Day',
      value: 'onsite',
    },
  ];
  const onSiteMenuOptions = [
    {
      label: t('EDIT_SESSION'),
      onClick: (id:string) => setEditOnsiteModal({
        isOpen: true,
        id,
        name: ModalTypes.editOnsite,
      }),
      value: 'edit',
    },
  ];
  const virtualMenuOptions = [
    {
      label: t('RESCHEDULE_SESSION'),
      onClick: (id:string) =>{
        setEditOnsiteModal({
          isOpen: true,
          id,
          name: ModalTypes.rescheduleVirtual,
        });
      },
      disabled: false,
      value: 'reschedule',
    },
    {
      label: 'Cancel Session',
      onClick: (id:string) =>{
        setEditOnsiteModal({
          isOpen: true,
          id,
          name: ModalTypes.cancelVirtual,
        });
      },
      value: 'cancel',
    },
  ];
  const selectedOnsiteBooking = React.useMemo(() => {
    if (editOnsiteModal.isOpen && editOnsiteModal.id && meetings.length) {
      return meetings.find((meeting) => meeting.id === editOnsiteModal.id);
    }
    return undefined;
  }, [editOnsiteModal, meetings]);

  const handleViewDetails = (onsiteId: string) => {
    if (onsiteId) {
      navigate(`/onsite-days/${onsiteId}`);
    }
  };

  const handleJoin = ({
    type,
    meetingId,
  }: {
    type: string;
    meetingId: string;
  }) => {
    if (type === VideoRoomSDKs.dailyCo) {
      getMeetingData({ meetingId, channel: type });
    } else {
      openNewTab(`rooms/${type}/${meetingId}`, 'allBookings');
    }
  };

  const onModalClose = () => {
    setEditOnsiteModal({ isOpen: false, id: '', name: ModalTypes.noModal });
    refetchAllBookings?.();
  };

  return (
    <section className="booking-management-wrapper">
      <section className="header">
        <section className="left-content">
          <Typography size={24} weight="400">
            View all bookings
          </Typography>
        </section>
        <section className="right-content">
          <SelectBox
            values={sessionTypesOptions}
            onChange={(val) => setSessionType(val)}
            selectedValue={sessionType}
            minWidth="150px"
          />
        </section>
      </section>
      {!isLoading && meetings && !!meetings?.length && (
        <section className="booking-list-wrapper">
          {meetings?.map((meeting) => (
            <SessionCard
              locationInfo={meeting.location}
              providerDetails={{
                name: meeting.provider.profile.name,
                profileImage: meeting.provider.profile.profileImage,
              }}
              bookingType={meeting.providerRole}
              scheduledStartTime={meeting.scheduledStartTime}
              scheduledEndTime={meeting.scheduledEndTime}
              kebabMenuOptions={
                meeting.providerRole === ProviderRoles.ONSITE
                  ? onSiteMenuOptions
                  : virtualMenuOptions.map((option) => ({
                    ...option,
                    disabled:
                      option.value === 'reschedule' &&
                      checkWithin24hrs(
                        Number(meeting.scheduledStartTime) * 1000,
                      ),
                  }))
              }
              key={meeting.id}
              sessionId={meeting.id}
              btnLabel={
                meeting.providerRole === ProviderRoles.ONSITE
                  ? 'View Details'
                  : 'Join'
              }
              handleSessionCardClick={
                meeting.providerRole === ProviderRoles.ONSITE
                  ? () => handleViewDetails(meeting.onsiteId.toString())
                  : () =>
                    handleJoin({ type: meeting.type, meetingId: meeting.id })
              }
            />
          ))}
          {editOnsiteModal.isOpen &&
            editOnsiteModal.name === ModalTypes.editOnsite &&
            selectedOnsiteBooking && (
            <OnsiteBookCancelPopUp
              eventId={selectedOnsiteBooking.onsiteId.toString()}
              counsellingStartTiming={`${moment
                .unix(Number(selectedOnsiteBooking.scheduledStartTime)).tz(getTimeZone())
                .format(OnsiteDateFormat)} |
              ${moment.unix(Number(selectedOnsiteBooking.scheduledStartTime)).tz(getTimeZone()).format('hh:mm A z')} onwards`}
              counsellingLocationInfo={`${selectedOnsiteBooking.location.address}, ${selectedOnsiteBooking.location.city}`}
              providerId={selectedOnsiteBooking.providerId}
              isRescheduleFlow
              meetingId={selectedOnsiteBooking.id}
              phoneNumber={phoneNumber as string}
              providerName={
                selectedOnsiteBooking.provider?.profile?.name as string
              }
              onclose={onModalClose}
            />
          )}
          {editOnsiteModal.isOpen &&
            editOnsiteModal.name === ModalTypes.rescheduleVirtual &&
            selectedOnsiteBooking && (
            <RescheduleSession
              onClose={onModalClose}
              role={selectedOnsiteBooking.providerRole}
              providerData={selectedOnsiteBooking as unknown as any}
              meetingData={selectedOnsiteBooking as unknown as any}
            />
          )}
          {editOnsiteModal.isOpen &&
            editOnsiteModal.name === ModalTypes.cancelVirtual &&
            selectedOnsiteBooking && (
            <CancelConfirmedSession
              onClose={onModalClose}
              meetingData={selectedOnsiteBooking as unknown as any}
              isWithin24hrs={checkWithin24hrs(
                Number(selectedOnsiteBooking.scheduledStartTime) * 1000,
              )}
            />
          )}
        </section>
      )}
      {!isLoading && !meetings?.length && (
        <NoDataPlaceholder
          headerText="No events booked"
          subHeaderText="Oops we didn’t found any event that you’re booked into, please book a session"
        />
      )}
    </section>
  );
}
